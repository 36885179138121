import React, { useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  Avatar,
} from "@mui/material";
import { FaLinkedin, FaGithub, FaEnvelope, FaPhone } from "react-icons/fa";
import { motion } from "framer-motion";
import { LanguageContext } from "../contexts/LanguageContext";
import profilePhoto from "../assets/images/profile.png"; // Profil fotoğrafını import edin

const sectionVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Resume = () => {
  const { content } = useContext(LanguageContext);
  const { resumeContent } = content;

  return (
    <>
      <Container sx={{ mt: 10, fontFamily: "Roboto, sans-serif" }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              transition: "transform 0.5s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}>
            <Avatar
              src={profilePhoto} // Profil fotoğrafını burada kullanın
              sx={{ width: 350, height: 450, borderRadius: "5%" }}
              variant="square"
            />
          </Box>
        </Box>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={sectionVariant}>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            gutterBottom
            sx={{ color: "#fff" }}>
            {resumeContent.name}
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            align="center"
            gutterBottom
            sx={{ color: "#fff" }}>
            {resumeContent.title}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            align="center"
            gutterBottom
            sx={{ color: "#ccc" }}>
            <a
              href={`mailto:${resumeContent.contact.email}`}
              style={{ color: "#ccc", textDecoration: "none" }}>
              <FaEnvelope /> {resumeContent.contact.email}
            </a>
            {" | "}
            <a
              href={`tel:${resumeContent.contact.phone}`}
              style={{ color: "#ccc", textDecoration: "none" }}>
              <FaPhone /> {resumeContent.contact.phone}
            </a>
            {" | "}
            <a
              href={`https://${resumeContent.contact.linkedin}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#ccc", textDecoration: "none" }}>
              <FaLinkedin /> {resumeContent.contact.linkedin}
            </a>
            {" | "}
            <a
              href={`https://${resumeContent.contact.github}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#ccc", textDecoration: "none" }}>
              <FaGithub /> {resumeContent.contact.github}
            </a>
          </Typography>
        </motion.div>

        <Box sx={{ mt: 5 }}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.summaryTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              {resumeContent.summary.map((paragraph, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  component="p"
                  sx={{
                    textIndent: "2em",
                    lineHeight: 1.6,
                    color: "#ccc",
                    marginBottom:
                      index < resumeContent.summary.length - 1 ? "1em" : 0,
                  }}>
                  {paragraph}
                </Typography>
              ))}
            </Paper>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.skillsTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              <Grid container spacing={2}>
                {resumeContent.skills.map((skill, index) => (
                  <Grid item xs={6} key={index}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ color: "#ccc" }}>
                      - {skill}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.experienceTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              {resumeContent.experience.map((exp, index) => (
                <Box sx={{ mb: 2 }} key={index}>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ color: "#fff" }}>
                    {exp.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#ccc" }}>
                    {exp.company}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#fff" }}>
                    {exp.period}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#ccc",
                      textIndent: "2em",
                      lineHeight: 1.6,
                      marginBottom: "1em",
                    }}>
                    {exp.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="ul"
                    sx={{ color: "#ccc", paddingLeft: "1em" }}>
                    {exp.responsibilities.map((item, i) => (
                      <li key={i} style={{ marginBottom: "0.5em" }}>
                        {item}
                      </li>
                    ))}
                  </Typography>
                  {index < resumeContent.experience.length - 1 && (
                    <Divider sx={{ backgroundColor: "#ccc" }} />
                  )}
                </Box>
              ))}
            </Paper>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.educationTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              {resumeContent.education.map((edu, index) => (
                <Box sx={{ mb: 2 }} key={index}>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ color: "#fff" }}>
                    {edu.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#ccc" }}>
                    {edu.institution}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#fff", fontStyle: "italic" }}>
                    {edu.period} {/* Yıllar burada gösterilecek */}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      color: "#ccc",
                      textIndent: "2em",
                      lineHeight: 1.6,
                      marginBottom: "1em",
                    }}>
                    {edu.description}
                  </Typography>
                  {index < resumeContent.education.length - 1 && (
                    <Divider sx={{ backgroundColor: "#ccc" }} />
                  )}
                </Box>
              ))}
            </Paper>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.languagesTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              <Grid container spacing={2}>
                {resumeContent.languages.map((language, index) => (
                  <Grid item xs={6} key={index}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ color: "#ccc" }}>
                      - {language.language}: {language.proficiency}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={sectionVariant}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              sx={{ color: "#fff" }}>
              {resumeContent.certificatesTitle}
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
              {resumeContent.certificates.map((certificate, index) => (
                <div key={index} style={{ marginBottom: "1em" }}>
                  <a
                    href={`/certificates/${certificate.certificate.replaceAll(
                      " ",
                      "_"
                    )}.pdf`}
                    style={{ color: "#ccc", textDecoration: "none" }}
                    target="_blank">
                    - {certificate.certificate}
                  </a>
                </div>
              ))}
            </Paper>
          </motion.div>
        </Box>
      </Container>
    </>
  );
};

export default Resume;
