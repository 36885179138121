import React, { useContext, useEffect } from "react";
import Section1 from "../containers/Section1";
import Section5 from "../containers/Section5";
import Section4 from "../containers/Section4";
import Section3 from "../containers/Section3";
import Section2 from "../containers/Section2";
import { LanguageContext } from "../contexts/LanguageContext";
import { useLocation } from "react-router-dom";

function Home() {
  const { content } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#section5") {
      document
        .getElementById("section5")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Section1 content={content.section1Content} />
      <Section2 content={content.section2Content} />
      <Section4 content={content.section4Content} />
      <Section3 content={content.section3Content} />
      <div id="section5">
        <Section5 content={content.section5Content} />
      </div>
    </>
  );
}

export default Home;
