import React, { useContext, useEffect, useRef } from "react";
import Typed from "typed.js";
import { LanguageContext } from "../../contexts/LanguageContext";

const TypeText = () => {
  const { content } = useContext(LanguageContext);
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: content.typedTextContent.strings, // Strings to display from context
      // Speed settings, try different values until you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: false,
    });

    // Destroying
    return () => {
      typed.destroy();
    };
  }, [content]);

  return (
    <div>
      <span>{content.typedTextContent.prefix} </span> <span ref={el}></span>
    </div>
  );
};

export default TypeText;
