import React, { createContext, useState } from "react";
import {
  section1Content as section1ContentEn,
  section2Content as section2ContentEn,
  section3Content as section3ContentEn,
  section4Content as section4ContentEn,
  section5Content as section5ContentEn,
  formContent as formContentEn,
  pages as pagesEn,
  aboutContent as aboutContentEn,
  footerContent as footerContentEn,
  typedTextContent as typedTextContentEn,
  resumeContent as resumeContentEn,
} from "../utils/content.en";
import {
  section1Content as section1ContentDe,
  section2Content as section2ContentDe,
  section3Content as section3ContentDe,
  section4Content as section4ContentDe,
  section5Content as section5ContentDe,
  formContent as formContentDe,
  pages as pagesDe,
  aboutContent as aboutContentDe,
  footerContent as footerContentDe,
  typedTextContent as typedTextContentDe,
  resumeContent as resumeContentDe,
} from "../utils/content.de";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const content = {
    en: {
      section1Content: section1ContentEn,
      section2Content: section2ContentEn,
      section3Content: section3ContentEn,
      section4Content: section4ContentEn,
      section5Content: section5ContentEn,
      formContent: formContentEn,
      pages: pagesEn,
      aboutContent: aboutContentEn,
      footerContent: footerContentEn,
      typedTextContent: typedTextContentEn,
      resumeContent: resumeContentEn,
    },
    de: {
      section1Content: section1ContentDe,
      section2Content: section2ContentDe,
      section3Content: section3ContentDe,
      section4Content: section4ContentDe,
      section5Content: section5ContentDe,
      formContent: formContentDe,
      pages: pagesDe,
      aboutContent: aboutContentDe,
      footerContent: footerContentDe,
      typedTextContent: typedTextContentDe,
      resumeContent: resumeContentDe,
    },
  };

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, content: content[language] }}>
      {children}
    </LanguageContext.Provider>
  );
};
