import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Particless = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log(container);
  }, []);

  const isMobile = window.innerWidth <= 768; // Mobil cihazları kontrol et

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: -1,
        pointerEvents: "none",
      }}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: "#000000", // Geçerli bir renk kodu kullanın
          },
          fpsLimit: 120,
          interactivity: {
            events: isMobile
              ? {} // Mobil cihazlarda etkileşimleri devre dışı bırak
              : {
                  onClick: {
                    enable: true,
                    mode: "push", // Fare tıklaması durumunda partiküller eklenir
                  },
                  onHover: {
                    enable: true,
                    mode: "attract", // Fareye doğru çekilme etkisi
                    parallax: { enable: true, force: 60, smooth: 10 }, // Parallax etkisi
                  },
                  resize: true,
                },
            modes: {
              push: {
                quantity: 0, // Fare tıklaması ile kaç partikül eklenecek
              },
              attract: {
                distance: 200, // Fareye olan mesafe
                duration: 0.4, // Etkileşim süresi
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 10,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  );
};

export default Particless;
