import * as React from "react";
import { useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { LanguageContext } from "../../contexts/LanguageContext";

function Copyright({ copyright }) {
  return (
    <Typography variant="caption" color="#fff">
      {"Copyright © "}
      <Link color="#fff" href="#">
        {copyright}
      </Link>{" "}
      {new Date().getFullYear()}
      {""}
    </Typography>
  );
}

const defaultTheme = createTheme();

function Footer() {
  const { content } = useContext(LanguageContext);
  const { aboutTitle, aboutText, followMeTitle, socialLinks, copyright } =
    content.footerContent;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        component="footer"
        sx={{
          py: 1,
          px: 1,
          mt: "auto",
          textAlign: "center",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.1)" // Light mode with 80% opacity
              : "rgba(0, 0, 0, 0.8)", // Dark mode with 80% opacity
          color: "text.secondary",
          position: "relative",
          zIndex: 2,
        }}>
        <Container maxWidth="xs">
          <Grid container spacing={1} direction="column" alignItems="center">
            {/* <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                {aboutTitle}
              </Typography>
              <Typography variant="caption">{aboutText}</Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: "#fff", fontSize: "small" }}>
                {followMeTitle}
              </Typography>
              {socialLinks.map((social) => (
                <IconButton
                  key={social.name}
                  aria-label={social.name}
                  component="a"
                  href={social.url}
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "#fff", fontSize: "small" }}>
                  {social.icon}
                </IconButton>
              ))}
            </Grid>
          </Grid>
          <Box mt={1}>
            <Copyright
              copyright={copyright}
              sx={{ color: "#fff", fontSize: "small" }}
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Footer;
