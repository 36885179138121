import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Title from "../components/Title/Title";

const Section4 = ({ content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showMore, setShowMore] = useState(false);
  const { subtitle, showMore: showMoreText, showLess: showLessText } = content;

  return (
    <Container sx={{ mt: { xs: 10, md: 20, lg: 25 } }}>
      <Box
        sx={{
          position: "relative",
          height: isMobile ? "auto" : "50vh",
          py: 5,
          borderRadius: "30px",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Arka planı transparan yaptık
          textAlign: "center",
          color: "#ccc", // Metin rengini gri yaptık
        }}>
        <Grid
          container
          flexWrap="nowrap"
          sx={{ px: { xs: 5, md: 8 } }}
          justifyContent="center">
          <Grid item xs={12} md={8}>
            <Stack spacing={2} justifyContent="center" sx={{ height: "100%" }}>
              <Title
                variant={{ xs: "h3", md: "h2" }}
                sx={{ height: "auto", mt: 3, color: "#fff", mb: 2 }}>
                {content.title}
              </Title>
              <Box
                sx={{
                  color: "#ccc", // Metin rengini gri yaptık
                  display: "block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: showMore ? "normal" : "nowrap",
                  maxHeight: showMore ? "none" : "6em",
                  transition: "max-height 0.3s ease",
                }}>
                <Typography
                  variant="body1"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: showMore ? "none" : 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {subtitle}
                </Typography>
              </Box>
              <Grid container justifyContent="center">
                <span
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    color: "#fff",
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                    transition: "background-color 0.3s, border-color 0.3s",
                    marginTop: "1rem",
                    padding: "0.5rem 1rem",
                    border: "1px solid #fff",
                  }}>
                  {showMore ? showLessText : showMoreText}
                </span>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Section4;
