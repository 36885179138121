import React, { useContext } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ConsultingForm from "../components/ConsultingForm/ConsultingForm";
import { LanguageContext } from "../contexts/LanguageContext";

const Section5 = () => {
  const { content } = useContext(LanguageContext);
  const {
    contactTitle,
    contactSubtitle,
    emailAddress,
    phoneNumber,
    location,
  } = content.section5Content;

  return (
    <Container sx={{ mt: 10 }}>
      <Box sx={{ textAlign: "center", mb: 5 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          {contactTitle}
        </Typography>
        <Typography variant="h6" component="p" color="textSecondary">
          {contactSubtitle}
        </Typography>
      </Box>
      <Grid container >
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6" component="h4">
              {emailAddress.title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {emailAddress.detail}
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6" component="h4">
              {phoneNumber.title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {phoneNumber.detail}
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6" component="h4">
              {location.title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {location.detail}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 0 }}>
            <ConsultingForm />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Section5;
