import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Resume from "./pages/Resume";
import Particless from "./components/Particless/Particless";
import Footer from "./components/Footers/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbars/Navbar";

const App = () => {
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}>
      <Particless />
      <Navbar />
      <ScrollToTop />
      <div style={{ flex: "1" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
