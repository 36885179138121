// Section3
import JavaIcon from "../assets/images/section3/Java.png";
import JavaSpringImg from "../assets/images/section3/spring.png";
import ReactIcon from "../assets/images/section3/react.png";
import CssIcon from "../assets/images/section3/css.png";
import DockerIcon from "../assets/images/section3/docker.png";
import HtmlIcon from "../assets/images/section3/html.png";
import JavascriptIcon from "../assets/images/section3/javascript.png";
import MongodbIcon from "../assets/images/section3/mongodb.png";
import MuiIcon from "../assets/images/section3/mui.png";
import MysqlIcon from "../assets/images/section3/mysql.png";
import PostgreIcon from "../assets/images/section3/postgresql.png";
import SassIcon from "../assets/images/section3/sass.png";
import NodeJsIcon from "../assets/images/section3/nodejs.png";
import TailwindIcon from "../assets/images/section3/tailwindcss.png";

// Section10
import LinkedinImage from "../assets/images/section10/linkedin.png";
import InstagramImage from "../assets/images/section10/instagram.png";
import GithubImage from "../assets/images/section10/github.png";
import TwitterImage from "../assets/images/section10/twitter.webp";

import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Navbar
import Logo from "../assets/images/logo.svg";

export const pages = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Resume", path: "/resume" },
  { name: "Contact Me", path: "/#section5" },
];

export const resumeContent = {
  name: "Kubilay Bircan",
  title: "Software Developer & Test Automation Specialist",
  contact: {
    email: "hkbircan@gmail.com",
    phone: "(176) 242-54-183",
    linkedin: "linkedin.com/in/hkbircan",
    github: "github.com/hkbircan",
  },
  summaryTitle: "Summary",
  summary: [
    "I hold a Bachelor's degree in Aerospace Engineering from the Military Academy in Turkey, recognized as equivalent by the German Central Office for Foreign Education. Since relocating to Germany in 2020, I have not only attained a B2 certificate in German but also proactively pursued and completed training and internships in software testing and software development engineering.",
    "Currently, I serve as a Performance Engineer, where I contribute to a variety of projects with a focus on automation and web development. My expertise lies in full-stack development, particularly with Java Spring Boot, JavaScript, React, and other modern web technologies.",
    "My diverse experiences, both professional and personal, have equipped me with the ability to quickly master new subject areas, as reflected in my resume. I am deeply passionate about coding, tackling complex challenges, and developing high-quality software solutions. I am constantly seeking to expand my knowledge of new technologies, tools, and libraries, and I am enthusiastic about the opportunity to bring my skills and passion to your team.",
  ],
  skillsTitle: "Skills",
  skills: [
    "Java, Spring Boot",
    "JavaScript, TypeScript",
    "React, Redux",
    "HTML, CSS, SASS",
    "Postgresql, MongoDB",
    "Git, GitHub, CI/CD",
    "Next.js",
    "Selenium",
    "Docker",
    "Jira/Confluence",
  ],
  experienceTitle: "Experience",
  experience: [
    {
      title: "Performance Engineer",
      company: "Simpleworks GmbH, Duisburg",
      period: "April 2022 - Present",
      description:
        "I have been responsible for various tasks in my role, primarily focusing on both frontend and backend development, as well as automation testing within a cloud environment. My key responsibilities included:",
      responsibilities: [
        "Developing user interfaces and interactions for web applications.",
        "Creating backend components with Java and Spring Boot for a project, including the implementation of RESTful APIs and managing databases using PostgreSQL and JPA.",
        "Building server and database components of the application.",
        "Developing a plugin for Confluence using React, including integration and functionality testing.",
        "Designing and executing automated smoke and regression tests.",
        "Creating automation test scenarios.",
        "Participating in Daily Scrum, Sprint Planning, Sprint Review, and Sprint Retrospective meetings.",
        "Collaborating within the Cloud Stack Team and QA Team.",
      ],
    },
    {
      title: "Fighter Jet Pilot",
      company: "Turkish Air Forces, Izmir",
      period: "September 2015 – January 2019",
      description:
        "In my role as a jet pilot, I was responsible for ensuring national security through border surveillance and patrol missions. My key duties included:",
      responsibilities: [
        "Conducting calibration tests of navigation systems.",
        "Performing border patrol and surveillance missions.",
        "Maintaining accuracy and reliability in aviation operations.",
        "Executing quick decision-making in high-pressure environments.",
        "Coordinating with ground control for mission-critical updates.",
      ],
    },
  ],
  educationTitle: "Education",
  education: [
    {
      title: "B. Eng. Aerospace Engineering",
      institution: "Turkish Air Force Academy, Istanbul",
      period: "September 2011 – September 2015",
      description:
        "A graduate of aerospace engineering with in-depth knowledge in fundamental subjects such as aerodynamics, thermodynamics, materials science, structural analysis, propulsion systems, flight mechanics, and space dynamics. Gained extensive project experience, actively participating in aircraft and spacecraft design, testing, and analysis processes. Proficient in using computer-aided design (CAD) programs, simulation software, and analysis tools; skilled in software such as MATLAB, ANSYS, and SolidWorks. Completed courses in JAVA and other programming languages, enhancing software and programming skills. Demonstrates the ability to develop innovative solutions to complex engineering problems, interpret data to draw logical conclusions through analytical and critical thinking skills.",
    },
    {
      title: "German and Integration Course",
      institution: "VHS Hildburghausen",
      period: "January 2020 – April 2021",
      description: "Completed German and integration courses.",
    },
  ],
  languagesTitle: "Languages",
  languages: [
    {
      language: "Turkish",
      proficiency: "Native",
    },
    {
      language: "German",
      proficiency: "Fluent",
    },
    {
      language: "English",
      proficiency: "Fluent",
    },
  ],
  certificatesTitle: "Certificates",
  certificates: [
    {
      certificate: "ISTQB Foundation Level",
    },
    {
      certificate: "Telc B2 Deutsch",
    },
    {
      certificate: "SDET in Java",
    },
    {
      certificate: "Frontend Web Developer React",
    },
    {
      certificate: "Frontend Web Developer Java Script",
    },
    {
      certificate: "Frontend Web Developer HTML5",
    },
  ],
};

export const aboutContent = {
  title: "About Me",
  description1:
    "Hello! I am Kubilay Bircan. I am a software developer specialized in Java with extensive experience in various technologies. My journey in software development has led me deep into the world of React, Node.js, and other modern web technologies. My interest and eagerness to learn in this field motivate me daily and drive me to continuously improve myself.",
  description2:
    "Originally, I started my academic journey as an Aerospace Engineer, which has provided me with analytical thinking and problem-solving skills that help me tackle challenges in software projects. Despite being new to the web technology industry, I have completed various courses and developed a strong curiosity for this field.",
  description3:
    "I have worked on various projects, including web development, mobile application development, and backend systems, and these experiences have given me a broad perspective. I am always eager to learn new things and share my knowledge. I am open to collaboration and always ready for new projects. If you are interested in collaborating or discussing projects, feel free to reach out to me at any time.",
  contactButtonText: "Get in Touch",
};

export const footerContent = {
  aboutTitle: "About Me",
  aboutText:
    "I am a passionate software developer specializing in Java and various web technologies. Always eager to learn and collaborate on exciting projects.",
  quickLinksTitle: "Quick Links",
  quickLinks: [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Resume", path: "/resume" },
    { name: "Contact Me", path: "/#section5" },
  ],
  followMeTitle: "Follow Me",
  socialLinks: [
    {
      name: "GitHub",
      url: "https://github.com/hkbircan",
      icon: <GitHubIcon />,
    },
    {
      name: "LinkedIn",
      url: "https://linkedin.com/in/hkbircan",
      icon: <LinkedInIcon />,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/hkbircan",
      icon: <TwitterIcon />,
    },
  ],
  copyright: "hkbircan.com",
};

export const section1Content = {
  title: "Kubilay Bircan",
  subtitle: "",
};

export const typedTextContent = {
  prefix: "I'm a",
  strings: [
    "Fullstack Developer",
    "Aerospace Engineer",
    "Performance Engineer",
    "React Developer",
    "Java Developer",
    "Tech Enthusiast",
    "Innovative Coder",
    "Problem Solver",
    "Agile Practitioner",
    "Continuous Learner",
    "Backend Guru",
    "Frontend Developer",
    "API Developer",
    "Database Designer",
  ],
};

export const section2Content = {
  items: [
    { counter: 21, subtitle: "Completed Projects" },
    { counter: 7, subtitle: "Years of Experience" },
    { counter: 18, subtitle: "Happy Clients" },
  ],
};

export const section3Content = {
  title: "Technologies Used in Web Development ",
  ITEMS: [
    { logo: JavaIcon, name: "Java" },
    { logo: JavaSpringImg, name: "Java Spring" },
    { logo: ReactIcon, name: "React" },
    { logo: CssIcon, name: "Css" },
    { logo: DockerIcon, name: "Docker" },
    { logo: HtmlIcon, name: "Html" },
    { logo: JavascriptIcon, name: "Js" },
    { logo: MongodbIcon, name: "MongoDb" },
    { logo: MuiIcon, name: "Material UI" },
    { logo: MysqlIcon, name: "My Sql" },
    { logo: PostgreIcon, name: "Postgre Sql" },
    { logo: SassIcon, name: "Sass" },
    { logo: TailwindIcon, name: "Tailwindcss" },
    { logo: NodeJsIcon, name: "Node Js" },
  ],
};

export const section4Content = {
  title: "Why Choose Me",
  subtitle:
    "With over 5 years of experience in the industry, I bring a wealth of knowledge and expertise to every project. From full-stack development to performance engineering, I have a diverse skill set that allows me to tackle any challenge. My background as an aerospace engineer and former air force officer adds a unique perspective to my work. I am dedicated to delivering high-quality results and continuously improving my skills. Let's work together to create something amazing. Contact me today to discuss your project needs and see how I can help you achieve your goals.",
  showMore: "Show more",
  showLess: "Show less",
};

export const section5Content = {
  contactTitle: "Get In Touch",
  contactSubtitle: "I'm here to help and answer any questions you might have",
  emailAddress: {
    title: "Email Address",
    detail: "hkbircan@gmail.com",
  },
  phoneNumber: {
    title: "Phone",
    detail: "0176-242-54-183",
  },
  location: {
    title: "Location",
    detail: "Düsseldorf, Germany",
  },
};

export const formContent = {
  fullnamePlaceholder: "Full Name",
  emailPlaceholder: "Email",
  phonePlaceholder: "Phone Number",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
  honeypotPlaceholder: "Leave this field blank",
  submitButton: "Send Message",
  sendingButton: "Sending...",
  formStatusValid: "Message sent successfully!",
  formStatusInvalid: "Please ensure the form is filled out correctly.",
  formStatusError: "Failed to send message.",
};
export const section10Content = {
  SOCIALS: [
    {
      name: "Github",
      image: GithubImage,
      url: "https://github.com/hkbircan",
    },
    {
      name: "LinkedIn",
      image: LinkedinImage,
      url: "https://linkedin.com/in/hkbircan",
    },
    {
      name: "Instagram",
      image: InstagramImage,
      url: "https://instagram.com/hkbircan",
    },
    {
      name: "Twitter",
      image: TwitterImage,
      url: "https://twitter.com/hkbircan",
    },
  ],
};
export const navbarContent = {
  Logo,
};
