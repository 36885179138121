const XingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="38"
    height="38"
    viewBox="0 0 48 48">
    <path d="M9.187 12.901L12.999 19l-5.812 9.963C6.66 29.866 7.311 31 8.356 31h4.501c.712 0 1.37-.378 1.728-.993l5.805-9.964c.374-.641.361-1.437-.032-2.067l-3.773-6.036C16.221 11.355 15.58 11 14.89 11h-4.65C9.264 11 8.669 12.074 9.187 12.901zM32.431 5.047l-11.925 22c-.328.605-.322 1.337.017 1.936l7.902 14C28.779 43.611 29.445 44 30.166 44h4.472c1.04 0 1.696-1.12 1.188-2.027L27.999 28 39.83 6.158C40.357 5.184 39.652 4 38.544 4h-4.355C33.456 4 32.781 4.402 32.431 5.047z"></path>
  </svg>
);

export default XingIcon;
