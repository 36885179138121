import React, { useContext } from "react";
import {
  Box,
  Container,
  Typography,
  Avatar,
  Stack,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import profileImage from "../assets/images/profil.jpg";

const About = () => {
  const { content } = useContext(LanguageContext);
  const { title, description1, description2, description3, contactButtonText } =
    content.aboutContent;

  return (
    <>
      <Container sx={{ mt: 15, pb: 25, color: "#f0f0f0" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={5}
          alignItems="center"
          justifyContent="center"
          textAlign="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              transition: "transform 0.5s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
              mb: { xs: 3, md: 0 },
            }}>
            <Avatar
              alt="Kubilay Bircan"
              src={profileImage}
              sx={{ width: 350, height: 450, borderRadius: "5%" }}
              variant="square"
            />
          </Box>
          <Box sx={{ width: { xs: "100%", md: "60%" }, px: 3 }}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ color: "#d1d1d1" }}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#808080", lineHeight: 1.6 }}>
              {description1}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#808080", lineHeight: 1.6 }}>
              {description2}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#808080", lineHeight: 1.6 }}>
              {description3}
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Button
                variant="outlined"
                component={Link}
                to="/#section5"
                sx={{
                  color: "#808080",
                  borderColor: "#fff",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#fff",
                    color: "#fff",
                  },
                }}>
                {contactButtonText}
              </Button>
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default About;
