import { Container, Fab, Grid } from "@mui/material";
import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import XingIcon from "../../assets/icons/XingIcon";

const Connect = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 3, md: 2, lg: 2 } }}>
      <Grid container justifyContent="space-between">
        <Grid item key={1} sx={{ mr: 3 }}>
          <Fab
            variant="elevated"
            sx={{ mr: 2 }}
            href="https://github.com/hkbircan"
            target="_blank">
            <GitHubIcon />
          </Fab>
          <Fab
            variant="elevated"
            sx={{ mr: 2 }}
            href="https://linkedin.com/in/hkbircan"
            target="_blank">
            <LinkedInIcon />
          </Fab>
          <Fab
            variant="elevated"
            sx={{ mr: 2 }}
            href="https://instagram.com/hkbircan"
            target="_blank">
            <InstagramIcon />
          </Fab>
          <Fab
            variant="elevated"
            sx={{ mr: 2 }}
            href="https://www.xing.com/profile/HasanKubilay_Bircan"
            target="_blank">
            <XingIcon />
          </Fab>
          <Fab
            variant="elevated"
            sx={{ mr: 2 }}
            href="https://twitter.com/hkbircan"
            target="_blank">
            <TwitterIcon />
          </Fab>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Connect;
