import React, { useState, useEffect, useContext, useCallback } from "react";
import { LanguageContext } from "../../contexts/LanguageContext"; // Yolu kontrol et ve düzelt

const ConsultingForm = () => {
  const { content, language } = useContext(LanguageContext); // language değerini al
  const formContent = content.formContent;

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [honeypot, setHoneypot] = useState(""); // Honeypot field
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^0\d{0,19}$/;

    const newErrors = {};
    if (touched.fullname && !fullname) newErrors.fullname = true;
    if (touched.email && !emailRegex.test(email)) newErrors.email = true;
    if (touched.phone && !phoneRegex.test(phone)) newErrors.phone = true;
    if (touched.subject && (subject.length < 1 || subject.length > 100))
      newErrors.subject = true;
    if (touched.message && (message.length < 10 || message.length > 500))
      newErrors.message = true;

    setErrors(newErrors);
  }, [fullname, email, phone, subject, message, touched]);

  useEffect(() => {
    validateForm();
  }, [fullname, email, phone, subject, message, validateForm]);

  // .env dosyasındaki API URL'sini kullanın
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSendMail = async () => {
    setTouched({
      fullname: true,
      email: true,
      phone: true,
      subject: true,
      message: true,
    });
    validateForm();
    const isFormValid = Object.keys(errors).length === 0;
    if (!isFormValid) {
      setFormStatus(formContent.formStatusInvalid);
      return;
    }

    setIsLoading(true);

    const payload = {
      fullname,
      email,
      phone,
      subject,
      message,
      language, // language parametresini ekle
      formType: "consulting", // formType eklenir
    };

    try {
      const response = await fetch(`/api/sendMail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const contentType = response.headers.get("content-type");
      let result;
      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        result = await response.text();
      }

      setIsLoading(false);
      if (result.success) {
        setFormStatus(formContent.formStatusValid);
        setFullname("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setTouched({});
      } else {
        setFormStatus(result.message || formContent.formStatusError);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setFormStatus(formContent.formStatusError);
      setIsLoading(false);
    }
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    validateForm();
  };

  const getInputClassName = (field) => {
    if (errors[field]) return "inputError";
    return "input";
  };

  const isFormValid =
    Object.keys(errors).length === 0 &&
    fullname &&
    email &&
    phone &&
    subject &&
    message;

  return (
    <div className="formContainer">
      <input
        type="text"
        placeholder={formContent?.fullnamePlaceholder}
        value={fullname}
        onChange={(e) => setFullname(e.target.value)}
        onBlur={() => handleBlur("fullname")}
        className={`input ${getInputClassName("fullname")}`}
        autoComplete="new-password"
      />
      <input
        type="email"
        placeholder={formContent?.emailPlaceholder}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={() => handleBlur("email")}
        className={`input ${getInputClassName("email")}`}
        autoComplete="new-password"
      />
      <input
        type="text"
        placeholder={formContent?.phonePlaceholder}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        onBlur={() => handleBlur("phone")}
        className={`input ${getInputClassName("phone")}`}
        autoComplete="new-password"
      />
      <input
        type="text"
        placeholder={formContent?.subjectPlaceholder}
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        onBlur={() => handleBlur("subject")}
        className={`input ${getInputClassName("subject")}`}
        autoComplete="new-password"
      />
      <textarea
        placeholder={formContent?.messagePlaceholder}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onBlur={() => handleBlur("message")}
        className={`textarea ${getInputClassName("message")}`}
        autoComplete="new-password"
      />
      <div className="honeypotContainer">
        <input
          type="text"
          placeholder={formContent?.honeypotPlaceholder}
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          className="honeypot"
          autoComplete="new-password"
        />
      </div>
      <button
        onClick={handleSendMail}
        className={isFormValid ? "sendButton" : "sendButtonDisabled"}
        disabled={!isFormValid || isLoading}>
        {isLoading ? formContent?.sendingButton : formContent?.submitButton}
      </button>
      {formStatus && <p>{formStatus}</p>}
    </div>
  );
};

export default ConsultingForm;

// Inline CSS
const style = document.createElement("style");
style.innerHTML = `
.formContainer {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0); /* Semi-transparent background */
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.input,
.textarea {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #d1d1d1; /* Grey border */
  background-color: transparent; /* No background */
  color: #fff; /* White text */
}

.textarea {
  height: 120px; /* Increased height for better usability */
}

.inputError {
  border: 1px solid rgba(255, 0, 0, 0.671);
}

.inputValid {
  border: 1px solid #d1d1d1; /* Gri border */
}

.sendButton {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
  color: #d1d1d1;
  background-color: #1c7ee700; /* Blue button */
}
.sendButton:hover {/* White background */
    color: #ffffff; /* Black text */
  }

.sendButtonDisabled {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
}

.honeypotContainer {
  display: none;
}

.honeypot {
  display: none;
}
`;
document.head.appendChild(style);
