import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import TypeText from "../components/Type/TypeText";
import Connect from "../components/Buttons/Connect";

const Section1 = ({ content }) => {
  return (
    <Box>
      <Container>
        
      </Container>
      <Container sx={{ height: "80vh", zIndex: 0 }}>
        <Stack sx={{ height: "100%", px: 3 }} justifyContent="center">
          <Typography variant="h1">{content?.title}</Typography>
          <TypeText />
          <Stack direction="row">
            <Connect />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Section1;
